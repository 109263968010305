<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header">
            <b-col cols="8">
              <h2 class="mb-0"><i class="fa fa-plus"></i> เพิ่มการชำระเงิน</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(saveHandler)">
              <form-payment
                :item="item"
                :merges="merges"
                :action="`add`"
                :flagEdit="`true`"
                :images="images"
                :imageDeletes="imageDeletes"
              />
              <div class="float-right d-flex">
                <span
                  ><router-link
                    :to="'/payment'"
                    class="nav-link text-underline"
                  >
                    กลับ</router-link
                  ></span
                >
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button
                  type="submit"
                  variant="primary"
                  class="style_btn"
                  v-if="permission.actionData.flag_insert"
                  ><i class="fa fa-check"></i> บันทึก</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Constants from "../../../util/constants";
import DateUtils from "../../../util/dateUtils";
import StringUtils from "../../../util/StringUtils";
import formPayment from "./form.payment";
export default {
  name: "payment-view-page-payment-add",
  data() {
    return {
      permission: {
        appSlug: "payment",
        actionData: {},
      },
      item: {
        id: "",
        order_id: "",
        merge_id: "",
        type: Constants.payment_types[0].id,
        ref:
          "trx-" +
          DateUtils.dateFormat(new Date(), "ssmmDDYYYYMMHH") +
          StringUtils.random4Digit(),
        upload: "",
        created: "",
        updated: "",
        uploaded: "",
        status: 2,
        approved: "",
        amount: "",
        type_payment: "",
        file: null,
        payment: null,
        remark: "",
      },
      merges: [],
      images: [],
      imageDeletes: [],
    };
  },
  methods: {
    async saveHandler() {
      const file = this.item.upload;
      delete this.item.upload;
      this.item.upload_encode = "";
      let params = JSON.parse(JSON.stringify(this.item));
      params.payment = DateUtils.dateFormat(
        params.payment,
        "YYYY-MM-DD HH:mm:ss"
      );
      params.merges = JSON.stringify(this.merges);
      this.SpinnerService.showLoader();
      const result = await this.HttpServices.postData(`/ab_payment`, params);
      if (result && result.status.code == "200") {
        const s3Auth = await this.HttpServices.authenS3();
        const medias = [];
        if (file && file.length > 0) {
          if (s3Auth._info) {
            const accessToken = s3Auth._info.secret.accessToken;
            for (const f of file) {
              if (f instanceof File) {
                let params = new FormData();
                params.append("file", f);
                params.append("title", f.name);
                params.append("file_category", "order-payment-slip");
                const resultMedia = await this.HttpServices.postFormDataS3(
                  params,
                  accessToken
                );
                if (resultMedia.media && resultMedia.media.length > 0) {
                  const media = resultMedia.media[0];
                  const mediaUpload = {
                    id: media.id,
                    original_name: media.original_name,
                    mime_type: media.mime_type,
                    size: media.size,
                    url: media.url,
                  };

                  params = new FormData();
                  const thumbFile = await StringUtils.resizeBlobToFile({
                    file: f,
                    maxSize: 200,
                  });
                  params.append("file", thumbFile);
                  params.append("title", thumbFile.name);
                  params.append("file_category", "order-payment-slip");
                  let resultThumb = await this.HttpServices.postFormDataS3(
                    params,
                    accessToken
                  );
                  if (resultThumb.media && resultThumb.media.length > 0) {
                    const mediaThumb = resultThumb.media[0];
                    mediaUpload.id_t = mediaThumb.id;
                    mediaUpload.original_name_t = mediaThumb.original_name;
                    mediaUpload.mime_type_t = mediaThumb.mime_type;
                    mediaUpload.size_t = mediaThumb.size;
                    mediaUpload.url_t = mediaThumb.url;
                  }
                  medias.push(mediaUpload);
                }
              } else {
                const mediaUpload = {
                  id: f.id,
                  original_name: f.original_name,
                  mime_type: f.mime_type,
                  size: f.size,
                  url: f.url,
                };
                if (f.id_t) {
                  mediaUpload.id_t = f.id_t;
                  mediaUpload.original_name_t = f.original_name_t;
                  mediaUpload.mime_type_t = f.mime_type_t;
                  mediaUpload.size_t = f.size_t;
                  mediaUpload.url_t = f.url_t;
                }
                medias.push(mediaUpload);
              }
            }
          }
          if (medias.length > 0) {
            let params = JSON.parse(JSON.stringify(this.item));
            params.payment = DateUtils.dateFormat(
              params.payment,
              "YYYY-MM-DD HH:mm:ss"
            );
            params.merges = JSON.stringify(this.merges);
            params.upload = JSON.stringify(medias);
            params.file = JSON.stringify(medias);
            params.upload_encode = JSON.stringify(medias);
            params.media = JSON.stringify(medias);

            // let params = JSON.parse(JSON.stringify(this.item));
            console.log("update photos: ", params);
            await this.HttpServices.putData(
              `/ab_payment/upload/${this.item.id}`,
              params
            );
            await this.HttpServices.putData(
              `/ab_payment/${this.item.id}`,
              params
            );
          }
        }
        if (s3Auth._info) {
          const accessToken = s3Auth._info.secret.accessToken;
          if (this.imageDeletes && this.imageDeletes.length > 0) {
            for (const v of this.imageDeletes) {
              await this.HttpServices.deleteDataS3(v, accessToken);
            }
          }
        }
        this.AlertUtils.alertCallback("success", `บันทึกสำเร็จ`, () => {
          this.$router.push("/payment");
        });
      } else {
        this.AlertUtils.alert("warning", result.status.message);
      }
      this.SpinnerService.hideLoader();
    },
    onBackHandler() {
      this.$router.push("/payment");
    },
  },
  components: {
    formPayment,
  },
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );
  },
};
</script>
